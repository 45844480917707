.svgIcon {
  background-color: #006dba;
  width: auto;
  height: auto;
  border-radius: 50%;
  padding: 2.5px;
}

.TooltipCustom {
  .tooltip-inner {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0.3rem !important;
    color: black !important;
    font-size:14px;
    padding:20px !important;
  }
  .arrow::before{
    border-top: 5px solid #143357 !important;
    border-bottom: 1px solid black;
  }
}